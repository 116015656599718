import React, { useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import Text from '../../shared/Text';
import Button from '../../shared/Button';
import styles from '../../../utility/styles';
import { FoundersIconBlack, ProIconBlack } from '../../../utility/icons';
import { returnTimeString } from '../../../utility/helpers';


const StyledOffcanvas = styled(Offcanvas)`
    background-color: ${styles.colors.background};
    width: 50%;
    max-width: 600px;
    .offcanvas-header .btn-close {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M4.27566 4.27566C3.90811 4.64322 3.90811 5.23914 4.27566 5.60669L10.1984 11.5294L4.27566 17.4521C3.90811 17.8197 3.90811 18.4156 4.27566 18.7832C4.64322 19.1507 5.23914 19.1507 5.60669 18.7832L11.5294 12.8604L17.5859 18.9169C17.9535 19.2845 18.5494 19.2845 18.9169 18.9169C19.2845 18.5494 19.2845 17.9535 18.9169 17.5859L12.8604 11.5294L18.7832 5.60669C19.1507 5.23914 19.1507 4.64322 18.7832 4.27566C18.4156 3.90811 17.8197 3.90811 17.4521 4.27566L11.5294 10.1984L5.60669 4.27566C5.23914 3.90811 4.64322 3.90811 4.27566 4.27566Z' fill='white'/%3e%3c/svg%3e") center / 1em auto no-repeat;
    }
    .offcanvas-header {
        background-color: ${styles.colors.background};
        color: ${styles.colors.white};
        border-bottom: 1px solid ${styles.colors.darkGray};
        font-family: var(--Font-Family, "Nunito Sans");
        font-size: var(--Para, 16px);
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 150% */
    }
    .offcanvas-body {
        padding: 32px;
        height: calc(100% - 56px);
        position: relative;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled(Offcanvas.Title)`
    font-family: var(--Font-Family, "Nunito Sans");
    font-size: var(--Para, 16px);
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
`

const Header = styled(Offcanvas.Header)`
    padding: 16px 24px;
`;

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background-color: ${({ selected }) => selected ? styles.colors.darkGray : styles.colors.background};
    padding: 16px;
    border-radius: 4px;
    pointer-style: cursor;
`

const AddOnBox = styled.div`
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${styles.colors.darkGray};
`;

const FooterSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    border-top: 1px solid ${styles.colors.darkGray};
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
`;

const LinkButton = styled.div`
    color: ${styles.colors.lightGray};
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
`;

const SelectButton = styled.div`
    background-color: ${({ selected }) => selected ? styles.colors.success : styles.colors.background};
    padding: 4px 8px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 800;
`;

const RoundButton = styled(Button)`
    padding: 8px;
    background-color: ${styles.colors.mediumGray};
    margin: 0 8px;
`

const DetailsWrapper = styled.div`
    display: ${({ visible }) => visible ? 'flex' : 'none'};
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
`;

const Divider = styled.hr`
    color: ${styles.colors.darkGray};
    margin: 16px 0;
`

const PaymentSection = styled.div`
    margin: 24px 0 0 0;
`


/**
 * 
 * @param {{
 *   show: boolean,
 *   onHide: () => void,
 *   user: {
 *     subscriptionText: string,
 *     renewalText: string,
 *     subscriptionType: 'pro' | 'founders',
 *   },
 *   updateConnections: (qty:number) => Promise<void>,
 *   currentConnections: number,
 *   minConnections: number,
 *   maxConnections: number,
 *   connectionText: string,
 *   connectionPrice: string,
 *   connectionPriceTotal: string,
 *   details: [{item:string,price:string}],
 *   onComplete: () => Promise<void>,
 *   onFoundersCheckout: (plan:'monthly'|'annual') => Promise<void>,
 *   showFoundersUI: boolean,
 *   pricing: {
 *    monthly: string,
 *    annual: string,
 *    additional_vehicle_monthly: string,
 *    additional_vehicle_annual: string,
 *   },
 *   previewData: {
 *      new_quantity: number,
 *      amount_due: string,
 *      confirmation_key: string,
 *      renews_on: number,
 *      renewal_amount: {
 *          off_platform_string: string,
 *          vehicles_amount: string,
 *          plan_amount: string,
 *          total: string,
 *          interval: string,
 *      },
 *      payment_method: {
 *          details: {
 *              brand: string,
 *              last4: string,
 *              exp_month: number,
 *              exp_year: number,
 *              type: string
 *          },
 *          update_url: string
 *      }
 *   },
 *   loading: boolean,
 *   completing: boolean,
 *   isAdjust: boolean,
 *   vehicleConnectionsError: string,
 * }} props
 * @returns 
 */
export default function AddConnectionsMenu({
    show,
    onHide,
    user,
    updateConnections,
    currentConnections,
    minConnections,
    maxConnections,
    connectionText,
    connectionPriceTotal,
    connectionPrice,
    details = [],
    onComplete,
    onFoundersCheckout,
    showFoundersUI,
    pricing,
    previewData,
    loading,
    completing,
    isAdjust = true,
    vehicleConnectionsError,
}) {

    const [selectedPlan, setSelectedPlan] = React.useState('annual')
    const [showDetails, setShowDetails] = React.useState(false)

    const selectMonthly = () => {
        setSelectedPlan('monthly')
    }

    const selectAnnual = () => {
        setSelectedPlan('annual')
    }

    const onFoundersCheckoutInternal = async () => {
        await onFoundersCheckout(selectedPlan)
    }

    const onDecreasePress = () => {
        if (currentConnections <= minConnections) return
        updateConnections(currentConnections - 1)
    }

    const onIncreasePress = () => {
        if (currentConnections >= maxConnections) return
        updateConnections(currentConnections + 1)
    }

    const toggleDetails = () => {
        setShowDetails(!showDetails)
    }

    return (
        <StyledOffcanvas show={show} onHide={onHide} placement='end' responsive='md'>
            <Header closeButton>
                <Title>{isAdjust ? 'Adjust' : 'Add'} Vehicle Connections</Title>
            </Header>
            <Offcanvas.Body>
                {!!vehicleConnectionsError && (
                    <Text.Body color={styles.colors.error} style={{ marginBottom: '16px' }}>{vehicleConnectionsError}</Text.Body>
                )}
                <StyledRow>
                    <StyledCol>
                        <Text.Caption color={styles.colors.founders}>{user?.subscriptionText}</Text.Caption>
                        <Text.Caption color={styles.colors.lightGray}>{user?.renewalText}</Text.Caption>
                    </StyledCol>
                    {user?.subscriptionType === 'pro' && (
                        <ProIconBlack />
                    )}
                    {user?.subscriptionType === 'founders' && (
                        <FoundersIconBlack />
                    )}
                </StyledRow>
                {!showFoundersUI && (
                    <AddOnBox>
                        <StyledRow style={{ flex: 1, justifyContent: 'space-between' }}>
                            <Text.Caption>Vehicle Connections (1 Included)</Text.Caption>
                            <StyledRow>
                                {!showFoundersUI && (
                                    <RoundButton disabled={loading} variant='secondary' onClick={onDecreasePress}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M3.33464 8.66665C2.96645 8.66665 2.66797 8.36817 2.66797 7.99998V7.99998C2.66797 7.63179 2.96645 7.33331 3.33464 7.33331L12.668 7.33331C13.0362 7.33331 13.3346 7.63179 13.3346 7.99998V7.99998C13.3346 8.36817 13.0362 8.66665 12.668 8.66665H3.33464Z" fill="white" />
                                        </svg>
                                    </RoundButton>
                                )}
                                {loading ? (
                                    <Spinner animation='border' variant='light' />
                                ) : (
                                    <Text.Heading variant='bold' type='h4'>{currentConnections}</Text.Heading>
                                )}
                                {!showFoundersUI && (
                                    <RoundButton disabled={loading} variant='secondary' onClick={onIncreasePress}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.9987 1.33331C7.63051 1.33331 7.33203 1.63179 7.33203 1.99998V7.33331H1.9987C1.63051 7.33331 1.33203 7.63179 1.33203 7.99998C1.33203 8.36817 1.63051 8.66665 1.9987 8.66665H7.33203V14C7.33203 14.3682 7.63051 14.6666 7.9987 14.6666C8.36689 14.6666 8.66536 14.3682 8.66536 14V8.66665H13.9987C14.3669 8.66665 14.6654 8.36817 14.6654 7.99998C14.6654 7.63179 14.3669 7.33331 13.9987 7.33331H8.66536V1.99998C8.66536 1.63179 8.36689 1.33331 7.9987 1.33331Z" fill="white" />
                                        </svg>
                                    </RoundButton>
                                )}
                            </StyledRow>
                        </StyledRow>
                    </AddOnBox>
                )}
                {showFoundersUI ? (
                    <React.Fragment>
                        <StyledRow>
                            <Text.Caption>You receive lifetime access to TezLab Pro as part of your Founders Series membership. TezLab Pro includes connection to one (1) vehicle, and Founders Series members receive one (1) additional connection at no charge for a total of two (2) vehicle connections. To connect to more vehicles, additional vehicle connections can be purchased.</Text.Caption>
                        </StyledRow>
                        <AddOnBox style={{ margin: '24px 0', padding: '8px' }}>
                            <StyledRow style={{ flex: 1 }}>
                                <SelectCol selected={selectedPlan === 'monthly'} onClick={selectMonthly}>
                                    <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '16px' }}>Billed Monthly</Text.Caption>
                                    <Text.Body variant='bold' >{pricing?.additional_vehicle_monthly}</Text.Body>
                                    <Text.Caption color={styles.colors.lightGray} style={{ marginBottom: '16px' }}>per vehicle per month</Text.Caption>
                                    <SelectButton selected={selectedPlan === 'monthly'}>
                                        {selectedPlan === 'monthly' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '4px' }}>
                                                <path d="M5.8266 11.1795L12.2772 3.1407C12.6213 2.71186 13.2455 2.64626 13.6712 2.99419C14.097 3.34211 14.1632 3.97181 13.819 4.40065L6.76962 13.1856C6.4255 13.6145 5.80138 13.6801 5.38585 13.3402L2.36676 11.0641C1.94099 10.7162 1.87481 10.0865 2.21893 9.65766C2.56305 9.22882 3.18717 9.16322 3.6027 9.50311L5.8266 11.1795Z" fill="white" />
                                            </svg>
                                        )}
                                        {selectedPlan === 'monthly' ? 'Selected' : 'Select'}
                                    </SelectButton>
                                </SelectCol>
                                <SelectCol selected={selectedPlan === 'annual'} onClick={selectAnnual}>
                                    <Text.Caption variant='bold' color={styles.colors.lightGray} style={{ marginBottom: '16px' }}>Billed Annually</Text.Caption>
                                    <Text.Body variant='bold' >{pricing?.additional_vehicle_annual}</Text.Body>
                                    <Text.Caption color={styles.colors.lightGray} style={{ marginBottom: '16px' }}>per vehicle per year</Text.Caption>
                                    <SelectButton selected={selectedPlan === 'annual'}>
                                        {selectedPlan === 'annual' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '4px' }}>
                                                <path d="M5.8266 11.1795L12.2772 3.1407C12.6213 2.71186 13.2455 2.64626 13.6712 2.99419C14.097 3.34211 14.1632 3.97181 13.819 4.40065L6.76962 13.1856C6.4255 13.6145 5.80138 13.6801 5.38585 13.3402L2.36676 11.0641C1.94099 10.7162 1.87481 10.0865 2.21893 9.65766C2.56305 9.22882 3.18717 9.16322 3.6027 9.50311L5.8266 11.1795Z" fill="white" />
                                            </svg>
                                        )}
                                        {selectedPlan === 'annual' ? 'Selected' : 'Select'}
                                    </SelectButton>
                                </SelectCol>
                            </StyledRow>
                        </AddOnBox>
                        <Button style={{ margin: '24px auto' }} onClick={onFoundersCheckoutInternal}>
                            Continue
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style={{ marginLeft: '8px' }}>
                                <path d="M16.67 13L13.09 16.59L14.5 18L20.5 12L14.5 6L13.09 7.41L16.67 11H4.5V13H16.67Z" fill="white" />
                            </svg>
                        </Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {!!previewData && (
                            <StyledRow style={{ marginTop: '24px' }}>
                                <StyledCol>
                                    <Text.Body>{`${previewData?.new_quantity} Vehicle Connection${previewData?.new_quantity > 1 ? 's' : ''}`}</Text.Body>
                                    {!!previewData?.renewal_amount?.vehicles_amount && <Text.Caption color={styles.colors.lightGray}>{previewData?.renewal_amount?.vehicles_amount} ({previewData?.renewal_amount?.interval})</Text.Caption>}
                                </StyledCol>
                                <StyledCol style={{ alignItems: 'flex-end' }}>
                                    <Text.Caption>Pro-Rated Amount Due Today</Text.Caption>
                                    <Text.Heading type='h4' variant='bold'>{previewData?.amount_due}</Text.Heading>
                                </StyledCol>
                            </StyledRow>
                        )}
                        {!!previewData?.payment_method && (
                            <PaymentSection>
                                <Text.Caption variant='bold' color={styles.colors.lightGray}>Payment Method</Text.Caption>
                                <Divider />
                                <StyledRow>
                                    <StyledRow>
                                        <Text.Body>{previewData.payment_method.details.brand} •••• {previewData.payment_method.details.last4}</Text.Body>
                                    </StyledRow>
                                    <Text.Body color={styles.colors.lightGray}>Expires {previewData.payment_method.details.exp_month}/{previewData.payment_method.details.exp_year}</Text.Body>
                                </StyledRow>
                            </PaymentSection>
                        )}
                         {!!previewData?.renewal_amount?.off_platform_string && (
                            <StyledRow style={{ marginTop: '24px', marginBottom: '24px' }}>
                                <Text.Caption color={previewData?.renewal_amount?.will_end_trial ? styles.colors.alert : null}>{previewData?.renewal_amount?.off_platform_string}</Text.Caption>
                            </StyledRow>
                        )}
                         {!!previewData?.reduction_string && (
                            <StyledRow style={{ marginTop: '24px', marginBottom: '24px' }}>
                                <Text.Caption>{previewData?.reduction_string}</Text.Caption>
                            </StyledRow>
                        )}
                        {!!previewData && (
                            <Button disabled={completing || loading} style={{ margin: '24px auto' }} onClick={onComplete}>
                                {completing ? <Spinner animation='border' variant='light' style={{ marginRight: '8px' }} /> : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style={{ marginRight: '8px' }}>
                                        <path d="M9.24186 16.7692L18.9178 4.71112C19.434 4.06785 20.3701 3.96945 21.0088 4.49134C21.6474 5.01323 21.7467 5.95778 21.2305 6.60104L10.6564 19.7785C10.1402 20.4218 9.20403 20.5202 8.58073 20.0103L4.05209 16.5963C3.41344 16.0744 3.31417 15.1298 3.83035 14.4866C4.34653 13.8433 5.2827 13.7449 5.906 14.2547L9.24186 16.7692Z" fill="white" />
                                    </svg>
                                )}
                                {completing ? 'Processing...' : previewData?.renewal_amount?.will_end_trial ? 'End Trial & Purchase' : Boolean(previewData?.reduction_string) ? 'Confirm Changes' : 'Complete Purchase'}
                            </Button>
                        )}
                    </React.Fragment>
                )}
                <FooterSection>
                    {!!previewData && (
                        <React.Fragment>
                            <StyledRow>
                                <StyledCol>
                                    <Text.Caption>{previewData?.renewal_amount?.will_end_trial ? `Recurring${previewData?.renewal_amount?.interval ? ` ${previewData?.renewal_amount?.interval}` : ''} starting` : 'Auto-renew on'} {returnTimeString(previewData?.renews_on, { format: 'MMM DD, YYYY' })}</Text.Caption>
                                    {!showDetails && <LinkButton onClick={toggleDetails}>Details</LinkButton>}
                                </StyledCol>
                                <Text.Heading type='h4' variant='bold'>{previewData?.renewal_amount?.total}</Text.Heading>
                            </StyledRow>
                            <DetailsWrapper visible={showDetails} onClick={toggleDetails}>
                                {!!previewData?.renewal_amount?.plan_amount && (
                                    <StyledRow style={{ margin: '16px 0' }}>
                                        <Text.Body>TezLab Pro</Text.Body>
                                        <Text.Body>{previewData?.renewal_amount?.plan_amount}</Text.Body>
                                    </StyledRow>
                                )}
                                <StyledRow>
                                </StyledRow>
                                <StyledRow>
                                    <Text.Body>Vehicle Connections x {previewData?.new_quantity}</Text.Body>
                                    <Text.Body>{previewData?.renewal_amount?.vehicles_amount}</Text.Body>
                                </StyledRow>
                            </DetailsWrapper>
                        </React.Fragment>
                    )}
                </FooterSection>
            </Offcanvas.Body>
        </StyledOffcanvas>
    )
}